<template>
  <base-list-view
    title="Сводные отчеты"
    @onRefresh="init"
    @onDelete="del"
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    pageEditName="ConsolidatedReportEdit"
  >
  </base-list-view>
</template>

<script>
import ListEditMixin from "@/mixins/ListEditMixin";
import SummaryReportService from "@/services/SummaryReportService";
import BaseListView from "../../layouts/BaseListView.vue";
import { SummaryReportStatus } from "@/data/enums";

import Permissions from "../../data/permissions";

export default {
  name: "view-SummaryReport",
  components: {
    BaseListView,
  },

  mixins: [ListEditMixin],
  data() {
    return {
      readOnly: !this.hasPermission(Permissions.AuditOperationsEdit),
      apiService: SummaryReportService,
      loading: true,
      headers: [
        { text: "Id", value: "Id", notGroup: true },
        {
          text: "Статус",
          value: "Status",
          dataType: "enum",
          options: SummaryReportStatus,
        },
      ],

      dataSource: [],
    };
  },

  created() {
    this.init();
  },
  methods: {},
};
</script>
